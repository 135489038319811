import * as React from 'react'

import colors from '@starchive/jasmine/colors'
import { useDarkMode } from '@starchive/dark-mode'

export function Logo({
  size = 120,
  color = null,
  isExtended,
}: ILogo): JSX.Element {
  const { isDark } = useDarkMode()
  const copyColor = isDark ? colors.gray[50] : colors.gray[900]

  if (isExtended) {
    return (
      <svg viewBox='0 0 517.95 130.6' width={size}>
        <path
          fill='#C71C8D'
          d='M94.29,77.44l-23.93-9.69c-0.55-0.22-1.09,0.33-0.86,0.88l10.33,23.81c0.08,0.18,0.22,0.31,0.41,0.37
	l20.57,6.52c0.52,0.16,1-0.33,0.83-0.84l-6.97-20.64C94.61,77.66,94.47,77.52,94.29,77.44z'
        />
        <path
          fill='#814098'
          d='M75.06,94.85l-9.45-21.67c-0.23-0.53-0.99-0.53-1.22,0.01l-9.18,21.56c-0.07,0.18-0.07,0.37,0.01,0.55
	l10.5,21.76c0.25,0.52,1.01,0.49,1.22-0.06l8.12-21.65C75.14,95.18,75.13,95,75.06,94.85z'
        />
        <path
          fill='#F6D113'
          d='M65.35,53.87l9.04-21.42c0.07-0.17,0.07-0.36,0-0.52l-9.62-21.76c-0.24-0.54-1.01-0.52-1.22,0.03l-8.68,22.2
	c-0.06,0.16-0.06,0.35,0.01,0.51l9.26,20.98C64.37,54.41,65.12,54.4,65.35,53.87z'
        />
        <path
          fill='#2F8196'
          d='M49.6,34.71l-20.39-6.16c-0.52-0.16-0.99,0.34-0.82,0.84l6.9,20.42c0.06,0.19,0.21,0.33,0.39,0.41l23.93,9.22
	c0.55,0.21,1.08-0.35,0.84-0.89L50.01,35.08C49.93,34.9,49.78,34.77,49.6,34.71z'
        />
        <path
          fill='#1D4697'
          d='M33.33,72.61l21.11-8.25c0.57-0.22,0.56-1.02-0.01-1.24l-20.77-7.74c-0.15-0.06-0.32-0.06-0.47,0L12.7,63.16
	c-0.56,0.21-0.57,1-0.01,1.23l20.15,8.21C32.99,72.67,33.17,72.67,33.33,72.61z'
        />
        <path
          fill='#403B95'
          d='M29.98,99.88l20.08-6.75c0.18-0.06,0.33-0.2,0.4-0.37l10.11-23.95c0.23-0.55-0.32-1.1-0.87-0.87l-23.77,9.95
	c-0.18,0.08-0.32,0.23-0.38,0.41l-6.42,20.75C28.97,99.57,29.47,100.05,29.98,99.88z'
        />
        <path
          fill='#DA1F31'
          d='M97.15,72.51l20.29-8.9c0.54-0.24,0.52-1-0.02-1.22l-20.39-8.04c-0.16-0.06-0.33-0.06-0.49,0l-21.41,8.56
	c-0.56,0.22-0.55,1.01,0.01,1.23l21.5,8.38C96.81,72.59,96.99,72.58,97.15,72.51z'
        />
        <path
          fill='#E84D25'
          d='M70.09,59.3l23.77-9.95c0.18-0.08,0.32-0.23,0.38-0.41l6.42-20.75c0.16-0.52-0.33-0.99-0.84-0.82l-20.08,6.75
	c-0.18,0.06-0.33,0.2-0.4,0.37L69.22,58.43C68.99,58.98,69.54,59.53,70.09,59.3z'
        />
        <g>
          <path
            fill={copyColor}
            d='M175.16,81.78c0,2.15-0.43,4.12-1.3,5.91c-0.87,1.79-2.05,3.32-3.54,4.61c-1.49,1.28-3.22,2.28-5.18,2.99
		c-1.96,0.71-4.02,1.07-6.17,1.07h-4.27c-2.15,0-4.21-0.41-6.17-1.22c-1.96-0.82-3.68-1.93-5.15-3.36c-1.48-1.42-2.66-3.09-3.54-5
		c-0.89-1.91-1.33-3.94-1.33-6.09v-2.13l6.4-1.04v3.54c0,1.28,0.27,2.49,0.81,3.62c0.54,1.13,1.26,2.11,2.16,2.94
		c0.9,0.83,1.94,1.49,3.12,1.98c1.18,0.49,2.41,0.73,3.7,0.73h4.27c1.28,0,2.52-0.19,3.7-0.57c1.18-0.38,2.23-0.93,3.15-1.64
		c0.92-0.71,1.65-1.57,2.19-2.58c0.54-1.01,0.81-2.15,0.81-3.44c0-1.91-0.42-3.47-1.25-4.69c-0.83-1.21-1.94-2.23-3.31-3.05
		c-1.37-0.81-2.93-1.49-4.69-2.03c-1.75-0.54-3.55-1.08-5.39-1.61c-1.84-0.54-3.64-1.15-5.39-1.85c-1.75-0.69-3.31-1.6-4.69-2.73
		c-1.37-1.13-2.47-2.54-3.31-4.24c-0.83-1.7-1.25-3.82-1.25-6.35c0-2.15,0.42-4.12,1.25-5.91s1.96-3.32,3.38-4.61
		c1.42-1.28,3.08-2.28,4.97-2.99c1.89-0.71,3.91-1.07,6.07-1.07h3.75c2.15,0,4.18,0.41,6.09,1.22c1.91,0.82,3.57,1.94,5,3.36
		c1.42,1.42,2.54,3.09,3.36,5c0.81,1.91,1.22,3.94,1.22,6.09v0.21l-6.4,1.04v-1.61c0-1.28-0.24-2.49-0.73-3.62
		c-0.49-1.13-1.15-2.11-1.98-2.94c-0.83-0.83-1.81-1.49-2.94-1.98c-1.13-0.49-2.33-0.73-3.62-0.73h-3.75
		c-1.28,0-2.49,0.19-3.62,0.57c-1.13,0.38-2.11,0.93-2.94,1.64c-0.83,0.71-1.49,1.57-1.98,2.58c-0.49,1.01-0.73,2.15-0.73,3.44
		c0,1.84,0.42,3.34,1.25,4.5c0.83,1.16,1.93,2.14,3.31,2.94c1.37,0.8,2.93,1.47,4.69,2c1.75,0.54,3.54,1.09,5.36,1.67
		s3.61,1.22,5.36,1.95c1.75,0.73,3.31,1.67,4.69,2.81c1.37,1.15,2.47,2.58,3.31,4.29C174.74,77.12,175.16,79.25,175.16,81.78z'
          />
          <path
            fill={copyColor}
            d='M206.87,95.32c-1.94,0-3.77-0.36-5.49-1.09c-1.72-0.73-3.21-1.74-4.48-3.02c-1.27-1.28-2.27-2.78-3.02-4.48
		c-0.75-1.7-1.12-3.52-1.12-5.47V48.1h-9.58v-6.09h9.58V29.2l6.4-1.04v13.85h13.54v6.09h-13.54v33.48c0,1.08,0.2,2.07,0.6,2.99
		c0.4,0.92,0.95,1.73,1.64,2.42c0.69,0.69,1.51,1.24,2.45,1.64c0.94,0.4,1.94,0.6,3.02,0.6h5.83v6.09H206.87z'
          />
          <path
            fill={copyColor}
            d='M257.94,95.32l-3.54-5.31c-1.46,1.91-3.28,3.45-5.47,4.61c-2.19,1.16-4.58,1.74-7.18,1.74h-1.61
		c-2.15,0-4.18-0.41-6.09-1.22c-1.91-0.82-3.58-1.93-5-3.36c-1.42-1.42-2.54-3.09-3.36-5c-0.82-1.91-1.22-3.94-1.22-6.09v-0.52
		c0-2.15,0.41-4.18,1.22-6.09c0.82-1.91,1.93-3.57,3.36-5c1.42-1.42,3.09-2.54,5-3.36c1.91-0.81,3.94-1.22,6.09-1.22h13.54v-8.23
		c0-1.28-0.24-2.49-0.73-3.62c-0.49-1.13-1.15-2.11-1.98-2.94s-1.81-1.49-2.94-1.98c-1.13-0.49-2.33-0.73-3.62-0.73h-3.75
		c-1.28,0-2.49,0.24-3.62,0.73c-1.13,0.49-2.11,1.15-2.94,1.98s-1.49,1.81-1.98,2.94c-0.49,1.13-0.73,2.33-0.73,3.62v1.93l-6.4-1.04
		v-0.52c0-2.15,0.41-4.18,1.22-6.09c0.81-1.91,1.93-3.57,3.36-5c1.42-1.42,3.09-2.54,5-3.36c1.91-0.81,3.94-1.22,6.09-1.22h3.75
		c2.15,0,4.18,0.41,6.09,1.22c1.91,0.82,3.57,1.94,5,3.36c1.42,1.42,2.54,3.09,3.36,5c0.81,1.91,1.22,3.94,1.22,6.09v38.68H257.94z
		 M253.67,69.5h-13.54c-1.28,0-2.49,0.24-3.62,0.73c-1.13,0.49-2.11,1.15-2.94,2c-0.83,0.85-1.49,1.84-1.98,2.97
		c-0.49,1.13-0.73,2.33-0.73,3.62v2.24c0,1.28,0.24,2.49,0.73,3.62c0.49,1.13,1.15,2.11,1.98,2.94s1.81,1.49,2.94,1.98
		c1.13,0.49,2.33,0.73,3.62,0.73h4.27c1.28,0,2.49-0.24,3.62-0.73c1.13-0.49,2.11-1.15,2.94-1.98s1.49-1.81,1.98-2.94
		c0.49-1.13,0.73-2.33,0.73-3.62V69.5z'
          />
          <path
            fill={copyColor}
            d='M288.55,48.1c-1.28,0-2.49,0.24-3.62,0.73c-1.13,0.49-2.11,1.15-2.94,1.98s-1.49,1.81-1.98,2.94
		c-0.49,1.13-0.73,2.33-0.73,3.62v37.95h-6.4V42.01h2.13l3.49,5.41c1.46-1.98,3.29-3.55,5.49-4.71c2.2-1.16,4.61-1.74,7.21-1.74
		h3.75v7.13H288.55z'
          />
          <path
            fill={copyColor}
            d='M337.88,86.78c-0.82,1.91-1.94,3.58-3.36,5c-1.42,1.42-3.09,2.54-5,3.36c-1.91,0.81-3.94,1.22-6.09,1.22h-4.27
		c-2.15,0-4.18-0.41-6.09-1.22c-1.91-0.82-3.58-1.93-5-3.36c-1.42-1.42-2.54-3.09-3.36-5c-0.82-1.91-1.22-3.94-1.22-6.09V56.64
		c0-2.15,0.41-4.18,1.22-6.09c0.82-1.91,1.93-3.57,3.36-5c1.42-1.42,3.09-2.54,5-3.36c1.91-0.81,3.94-1.22,6.09-1.22h4.27
		c2.15,0,4.18,0.41,6.09,1.22c1.91,0.82,3.58,1.94,5,3.36c1.42,1.42,2.54,3.09,3.36,5c0.82,1.91,1.22,3.94,1.22,6.09v2.13l-6.4,1.04
		v-3.54c0-1.28-0.24-2.49-0.73-3.62c-0.49-1.13-1.15-2.11-1.98-2.94s-1.81-1.49-2.94-1.98c-1.13-0.49-2.33-0.73-3.62-0.73h-4.27
		c-1.28,0-2.49,0.24-3.62,0.73c-1.13,0.49-2.11,1.15-2.94,1.98s-1.49,1.81-1.98,2.94c-0.49,1.13-0.73,2.33-0.73,3.62v24.78
		c0,1.28,0.24,2.49,0.73,3.62c0.49,1.13,1.15,2.11,1.98,2.94s1.81,1.49,2.94,1.98c1.13,0.49,2.33,0.73,3.62,0.73h4.27
		c1.28,0,2.49-0.24,3.62-0.73c1.13-0.49,2.11-1.15,2.94-1.98s1.49-1.81,1.98-2.94c0.49-1.13,0.73-2.33,0.73-3.62v-3.54l6.4,1.04
		v2.13C339.1,82.84,338.7,84.87,337.88,86.78z'
          />
          <path
            fill={copyColor}
            d='M381.12,95.32V56.27c0-1.28-0.24-2.49-0.73-3.62c-0.49-1.13-1.15-2.11-1.98-2.94s-1.81-1.49-2.94-1.98
		c-1.13-0.49-2.33-0.73-3.62-0.73h-4.27c-1.28,0-2.49,0.24-3.62,0.73c-1.13,0.49-2.11,1.15-2.94,1.98s-1.49,1.81-1.98,2.94
		c-0.49,1.13-0.73,2.33-0.73,3.62v39.05h-6.4V20.66h6.4v25.82c1.46-1.74,3.21-3.09,5.26-4.06c2.05-0.97,4.27-1.46,6.66-1.46h1.61
		c2.15,0,4.18,0.41,6.09,1.22c1.91,0.82,3.58,1.94,5,3.36c1.42,1.42,2.54,3.09,3.36,5c0.82,1.91,1.22,3.94,1.22,6.09v38.68H381.12z'
          />
          <path fill={copyColor} d='M402.46,95.32V42.01h6.4v53.31H402.46z' />
          <path
            fill={copyColor}
            d='M440.52,95.32h-3.18l-18.9-53.31h6.4l13.9,40.4l0.21,2.76l0.21-2.76l13.85-40.4h6.4L440.52,95.32z'
          />
          <path
            fill={copyColor}
            d='M501.25,86.78c-0.82,1.91-1.94,3.58-3.36,5c-1.42,1.42-3.09,2.54-5,3.36c-1.91,0.81-3.94,1.22-6.09,1.22h-4.27
		c-2.15,0-4.18-0.41-6.09-1.22c-1.91-0.82-3.58-1.93-5-3.36c-1.42-1.42-2.54-3.09-3.36-5c-0.82-1.91-1.22-3.94-1.22-6.09V56.64
		c0-2.15,0.41-4.18,1.22-6.09c0.82-1.91,1.93-3.57,3.36-5c1.42-1.42,3.09-2.54,5-3.36c1.91-0.81,3.94-1.22,6.09-1.22h4.27
		c2.15,0,4.18,0.41,6.09,1.22c1.91,0.82,3.58,1.94,5,3.36c1.42,1.42,2.54,3.09,3.36,5c0.82,1.91,1.22,3.94,1.22,6.09v12.55h-29.21
		v11.87c0,1.28,0.24,2.49,0.73,3.62c0.49,1.13,1.15,2.11,1.98,2.94s1.81,1.49,2.94,1.98c1.13,0.49,2.33,0.73,3.62,0.73h4.27
		c1.28,0,2.49-0.24,3.62-0.73c1.13-0.49,2.11-1.15,2.94-1.98s1.49-1.81,1.98-2.94c0.49-1.13,0.73-2.33,0.73-3.62v-3.54l6.4,1.04
		v2.13C502.48,82.84,502.07,84.87,501.25,86.78z M496.07,56.27c0-1.28-0.24-2.49-0.73-3.62c-0.49-1.13-1.15-2.11-1.98-2.94
		s-1.81-1.49-2.94-1.98c-1.13-0.49-2.33-0.73-3.62-0.73h-4.27c-1.28,0-2.49,0.24-3.62,0.73c-1.13,0.49-2.11,1.15-2.94,1.98
		s-1.49,1.81-1.98,2.94c-0.49,1.13-0.73,2.33-0.73,3.62v7.91h22.8V56.27z'
          />
        </g>
        <circle fill={copyColor} cx='405.63' cy='30.43' r='4.31' />
        <circle fill='#C71C8D' cx='107.27' cy='79.48' r='4.31' />
        <circle fill='#2F8196' cx='25.37' cy='49.25' r='4.31' />
        <path fill='#2F8196' d='M18.42,45.58' />
      </svg>
    )
  }

  if (color) {
    return (
      <svg viewBox='0 0 127.26 130.6' width={size} height={size}>
        <path
          fill={color}
          d='M94.29,77.44l-23.93-9.69c-0.55-0.22-1.09,0.33-0.86,0.88l10.33,23.81c0.08,0.18,0.22,0.31,0.41,0.37
	l20.57,6.52c0.52,0.16,1-0.33,0.83-0.84l-6.97-20.64C94.61,77.66,94.47,77.52,94.29,77.44z'
        />
        <path
          fill={color}
          d='M75.06,94.85l-9.45-21.67c-0.23-0.53-0.99-0.53-1.22,0.01l-9.18,21.56c-0.07,0.18-0.07,0.37,0.01,0.55
	l10.5,21.76c0.25,0.52,1.01,0.49,1.22-0.06l8.12-21.65C75.14,95.18,75.13,95,75.06,94.85z'
        />
        <path
          fill={color}
          d='M65.35,53.87l9.04-21.42c0.07-0.17,0.07-0.36,0-0.52l-9.62-21.76c-0.24-0.54-1.01-0.52-1.22,0.03l-8.68,22.2
	c-0.06,0.16-0.06,0.35,0.01,0.51l9.26,20.98C64.37,54.41,65.12,54.4,65.35,53.87z'
        />
        <path
          fill={color}
          d='M49.6,34.71l-20.39-6.16c-0.52-0.16-0.99,0.34-0.82,0.84l6.9,20.42c0.06,0.19,0.21,0.33,0.39,0.41l23.93,9.22
	c0.55,0.21,1.08-0.35,0.84-0.89L50.01,35.08C49.93,34.9,49.78,34.77,49.6,34.71z'
        />
        <path
          fill={color}
          d='M33.33,72.61l21.11-8.25c0.57-0.22,0.56-1.02-0.01-1.24l-20.77-7.74c-0.15-0.06-0.32-0.06-0.47,0L12.7,63.16
	c-0.56,0.21-0.57,1-0.01,1.23l20.15,8.21C32.99,72.67,33.17,72.67,33.33,72.61z'
        />
        <path
          fill={color}
          d='M29.98,99.88l20.08-6.75c0.18-0.06,0.33-0.2,0.4-0.37l10.11-23.95c0.23-0.55-0.32-1.1-0.87-0.87l-23.77,9.95
	c-0.18,0.08-0.32,0.23-0.38,0.41l-6.42,20.75C28.97,99.57,29.47,100.05,29.98,99.88z'
        />
        <path
          fill={color}
          d='M97.15,72.51l20.29-8.9c0.54-0.24,0.52-1-0.02-1.22l-20.39-8.04c-0.16-0.06-0.33-0.06-0.49,0l-21.41,8.56
	c-0.56,0.22-0.55,1.01,0.01,1.23l21.5,8.38C96.81,72.59,96.99,72.58,97.15,72.51z'
        />
        <path
          fill={color}
          d='M70.09,59.3l23.77-9.95c0.18-0.08,0.32-0.23,0.38-0.41l6.42-20.75c0.16-0.52-0.33-0.99-0.84-0.82l-20.08,6.75
	c-0.18,0.06-0.33,0.2-0.4,0.37L69.22,58.43C68.99,58.98,69.54,59.53,70.09,59.3z'
        />
        <circle fill={color} cx='405.63' cy='30.43' r='4.31' />
        <circle fill={color} cx='106.27' cy='78.48' r='4.31' />
        <circle fill={color} cx='25.37' cy='49.25' r='4.31' />
        <path fill={color} d='M18.42,45.58' />
      </svg>
    )
  }

  return (
    <svg viewBox='0 0 127.26 130.6' width={size} height={size}>
      <path
        fill='#C71C8D'
        d='M94.29,77.44l-23.93-9.69c-0.55-0.22-1.09,0.33-0.86,0.88l10.33,23.81c0.08,0.18,0.22,0.31,0.41,0.37
	l20.57,6.52c0.52,0.16,1-0.33,0.83-0.84l-6.97-20.64C94.61,77.66,94.47,77.52,94.29,77.44z'
      />
      <path
        fill='#814098'
        d='M75.06,94.85l-9.45-21.67c-0.23-0.53-0.99-0.53-1.22,0.01l-9.18,21.56c-0.07,0.18-0.07,0.37,0.01,0.55
	l10.5,21.76c0.25,0.52,1.01,0.49,1.22-0.06l8.12-21.65C75.14,95.18,75.13,95,75.06,94.85z'
      />
      <path
        fill='#F6D113'
        d='M65.35,53.87l9.04-21.42c0.07-0.17,0.07-0.36,0-0.52l-9.62-21.76c-0.24-0.54-1.01-0.52-1.22,0.03l-8.68,22.2
	c-0.06,0.16-0.06,0.35,0.01,0.51l9.26,20.98C64.37,54.41,65.12,54.4,65.35,53.87z'
      />
      <path
        fill='#2F8196'
        d='M49.6,34.71l-20.39-6.16c-0.52-0.16-0.99,0.34-0.82,0.84l6.9,20.42c0.06,0.19,0.21,0.33,0.39,0.41l23.93,9.22
	c0.55,0.21,1.08-0.35,0.84-0.89L50.01,35.08C49.93,34.9,49.78,34.77,49.6,34.71z'
      />
      <path
        fill='#1D4697'
        d='M33.33,72.61l21.11-8.25c0.57-0.22,0.56-1.02-0.01-1.24l-20.77-7.74c-0.15-0.06-0.32-0.06-0.47,0L12.7,63.16
	c-0.56,0.21-0.57,1-0.01,1.23l20.15,8.21C32.99,72.67,33.17,72.67,33.33,72.61z'
      />
      <path
        fill='#403B95'
        d='M29.98,99.88l20.08-6.75c0.18-0.06,0.33-0.2,0.4-0.37l10.11-23.95c0.23-0.55-0.32-1.1-0.87-0.87l-23.77,9.95
	c-0.18,0.08-0.32,0.23-0.38,0.41l-6.42,20.75C28.97,99.57,29.47,100.05,29.98,99.88z'
      />
      <path
        fill='#DA1F31'
        d='M97.15,72.51l20.29-8.9c0.54-0.24,0.52-1-0.02-1.22l-20.39-8.04c-0.16-0.06-0.33-0.06-0.49,0l-21.41,8.56
	c-0.56,0.22-0.55,1.01,0.01,1.23l21.5,8.38C96.81,72.59,96.99,72.58,97.15,72.51z'
      />
      <path
        fill='#E84D25'
        d='M70.09,59.3l23.77-9.95c0.18-0.08,0.32-0.23,0.38-0.41l6.42-20.75c0.16-0.52-0.33-0.99-0.84-0.82l-20.08,6.75
	c-0.18,0.06-0.33,0.2-0.4,0.37L69.22,58.43C68.99,58.98,69.54,59.53,70.09,59.3z'
      />
      <circle fill='#58595B' cx='405.63' cy='30.43' r='4.31' />
      <circle fill='#C71C8D' cx='106.27' cy='78.48' r='4.31' />
      <circle fill='#2F8196' cx='25.37' cy='49.25' r='4.31' />
      <path fill='#2F8196' d='M18.42,45.58' />
    </svg>
  )
}

export interface ILogo {
  size?: number
  color?: string | null
  isExtended?: boolean
}
