const primary = {
  gray: {
    50: '#f9fafb',
    100: '#f0f1f3',
    200: '#d9dbdf',
    300: '#b7bbc2',
    400: '#8f959f',
    500: '#6e7582',
    600: '#555e6e',
    700: '#3e4859',
    800: '#283242',
    900: '#131f30',
  },

  red: {
    50: '#fff6f6',
    100: '#ffefef',
    200: '#f9d3d3',
    300: '#eaa6a6',
    400: '#e56d6d',
    500: '#d93c3e',
    600: '#bd0e0f',
    700: '#930705',
    800: '#680605',
    900: '#470201',
  },

  purple: {
    50: '#f8f9fb',
    100: '#ededfb',
    200: '#dccff8',
    300: '#c0a9ef',
    400: '#b080e5',
    500: '#9b5ddd',
    600: '#8341ce',
    700: '#6431ae',
    800: '#46237f',
    900: '#29184e',
  },

  orange: {
    50: '#fff8f1',
    100: '#fbecdf',
    200: '#f6d7bf',
    300: '#e5ad8b',
    400: '#d67a4a',
    500: '#c4540b',
    600: '#9c4303',
    700: '#713707',
    800: '#4a2702',
    900: '#2c1b01',
  },

  lemon: {
    50: '#fdfdea',
    100: '#fdf6be',
    200: '#f5e27b',
    300: '#d9bd2f',
    400: '#b6930d',
    500: '#967100',
    600: '#805501',
    700: '#643e02',
    800: '#422a00',
    900: '#271a00',
  },

  turquoise: {
    50: '#edf5f4',
    100: '#ccf0f2',
    200: '#93e6e3',
    300: '#5acdc1',
    400: '#22af99',
    500: '#189571',
    600: '#167e59',
    700: '#156147',
    800: '#104336',
    900: '#051414',
  },

  green: {
    50: '#f2fffc',
    100: '#e6fffa',
    200: '#bffff2',
    300: '#99ffeb',
    400: '#4dffdb',
    500: '#00ffcc',
    600: '#00e6b8',
    700: '#00bf99',
    800: '#00997a',
    900: '#005e4b',
  },

  leaf: {
    50: '#edfafa',
    100: '#d5f5f6',
    200: '#aae7ea',
    300: '#69c8ce',
    400: '#13a2ad',
    500: '#0d828d',
    600: '#056874',
    700: '#06505c',
    800: '#043744',
    900: '#01222f',
  },

  blue: {
    50: '#f2f8ff',
    100: '#e6f1ff',
    200: '#bfdbff',
    300: '#99c5ff',
    400: '#4d9aff',
    500: '#006eff',
    600: '#0063e6',
    700: '#0053bf',
    800: '#004299',
    900: '#00367d',
  },

  indigo: {
    50: '#f7f9fb',
    100: '#e7eefc',
    200: '#cfd2fa',
    300: '#aeaef3',
    400: '#9686eb',
    500: '#7f62e6',
    600: '#6a46da',
    700: '#5135bc',
    800: '#39258f',
    900: '#21195b',
  },

  cerise: {
    50: '#fff5fb',
    100: '#fdeaf5',
    200: '#fbd2e9',
    300: '#e9a7cc',
    400: '#de6ea8',
    500: '#d53485',
    600: '#b90263',
    700: '#90084c',
    800: '#5e1734',
    900: '#301820',
  },

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
}

const branded = {
  primary: primary.blue,
  neutral: primary.gray,
}

const colors = { ...primary, ...branded }

module.exports = colors
