import { atom, useAtom } from 'jotai'
/* eslint-disable */
/* @ts-ignore */
import nightwind from 'nightwind/helper'

const isSystemDarkMode = () =>
  typeof window !== 'undefined' &&
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches

export function useDarkMode() {
  const [theme, setTheme] = useAtom(themeAtom)

  /**
   * Toggles between light and dark modes.
   */
  function toggle() {
    if (theme !== 'dark') {
      nightwind.enable(true)
      setTheme('dark')

      return
    }

    nightwind.enable(false)
    setTheme('light')
  }

  return { toggle, isDark: theme === 'dark' }
}

/**
 * Inserts into DOM an initial script to have automatic
 * dark mode working.
 */
export function setupDarkMode(): JSX.Element {
  return <script dangerouslySetInnerHTML={{ __html: nightwind.init() }} />
}

const systemTheme = isSystemDarkMode() ? 'dark' : 'light'
const persistedTheme =
  typeof window !== 'undefined'
    ? (localStorage.getItem('nightwind-mode') as null | 'dark' | 'light')
    : 'light'
const initialTheme = persistedTheme || systemTheme

const themeAtom = atom<'dark' | 'light'>(initialTheme)
